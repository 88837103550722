<template>
  <v-card flat class="rounded-lg mt-4" :key="componentRerenderKey">
    <v-card-title>
      <span class="text-color-title body-1 font-weight-bold">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn small depressed dark color="black" @click="deleteComponent()">
        Delete
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="text-center">
          <div>
            <v-img :src="image" alt="background" />
          </div>
          <v-file-input
            v-model="file"
            :rules="isRequired ? [] : MIXINS_REQUIRED_RULES"
            accept="image/*"
            label="Upload photo"
            prepend-icon="mdi-camera"
            dense
            @change="createImage()"
            class="mt-5"
            :clearable="false"
            :validate-on-blur="true"
            :error-messages="file || isRequired ? [] : MIXINS_REQUIRED_RULES"
            append-icon="mdi-close"
            @click:append="defaultImage()"
          />
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { BUILDER_COMPONENT_EVENT_BUS } from "../../services/builderComponentEventBus";

export default {
  data: () => ({
    title: "Image",
    file: null,
    image: null,
    valid: false,
    componentRerenderKey: 0
  }),
  computed: {
    isRequired: function() {
      if (this.image) {
        return true;
      }

      return false;
    }
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    defaultImage() {
      this.file = null;
      this.image = null;
      this.componentRerenderKey++;
    },
    createImage() {
      let reader = new FileReader();

      reader.onload = e => {
        this.image = e.target.result;
      };

      reader.readAsDataURL(this.file);
    },
    deleteComponent() {
      let key = this.$attrs.componentKey;

      BUILDER_COMPONENT_EVENT_BUS.$emit("deleteComponent", {
        key,
        partId: this.itemId
      });
    },
    validate() {
      return this.$refs.form.validate();
    }
  },
  mounted() {
    setTimeout(() => {
      this.image = this.form.image;
      this.file = this.form.file || null;
    }, 50);
  }
};
</script>
